var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Justified Nav"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeJustified) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("For equal-width elements, set the ")]), _c('code', [_vm._v("justified")]), _c('span', [_vm._v(" prop instead. All horizontal space will be occupied by nav links, but unlike ")]), _c('code', [_vm._v("fill")]), _c('span', [_vm._v(" above, every ")]), _c('code', [_vm._v("<b-nav-item>")]), _c('span', [_vm._v(" will be the same width.")])]), _c('b-nav', {
    attrs: {
      "justified": ""
    }
  }, [_c('b-nav-item', {
    attrs: {
      "active": ""
    }
  }, [_vm._v(" Active ")]), _c('b-nav-item', [_vm._v("Link")]), _c('b-nav-item', [_vm._v("Link name ")]), _c('b-nav-item', {
    attrs: {
      "disabled": ""
    }
  }, [_vm._v(" Disabled ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }