var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "With Border"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeBorder) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Use Class ")]), _c('code', [_vm._v(".wrap-border")]), _c('span', [_vm._v(" with your ")]), _c('code', [_vm._v("<b-nav>")]), _c('span', [_vm._v(" tag to wrap your nav with a border.")])]), _c('b-nav', {
    staticClass: "wrap-border"
  }, [_c('b-nav-item', {
    attrs: {
      "active": ""
    }
  }, [_vm._v(" Active ")]), _c('b-nav-item', [_vm._v("Link")]), _c('b-nav-item', [_vm._v("Another Link")]), _c('b-nav-item', {
    attrs: {
      "disabled": ""
    }
  }, [_vm._v(" Disabled ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }