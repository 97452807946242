var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Alignment Nav"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeAlignment) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("To align your ")]), _c('code', [_vm._v("<b-nav-item>")]), _c('span', [_vm._v(" components, use the ")]), _c('code', [_vm._v("align")]), _c('span', [_vm._v(" prop. Available values are ")]), _c('code', [_vm._v("left, center")]), _c('span', [_vm._v(" and ")]), _c('code', [_vm._v("right")])]), _c('div', {
    staticClass: "mb-2"
  }, [_c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "left",
      "inline": ""
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, [_vm._v(" Left ")]), _c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "center",
      "inline": ""
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, [_vm._v(" Center ")]), _c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "right",
      "inline": ""
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, [_vm._v(" Right ")])], 1), _c('b-nav', {
    attrs: {
      "align": _vm.selected
    }
  }, [_c('b-nav-item', {
    attrs: {
      "active": ""
    }
  }, [_vm._v(" Active ")]), _c('b-nav-item', [_vm._v("Link")]), _c('b-nav-item', [_vm._v("Link with a long name ")]), _c('b-nav-item', {
    attrs: {
      "disabled": ""
    }
  }, [_vm._v(" Disabled ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }