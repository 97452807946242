var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Dropdown Nav"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeDropdown) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Use ")]), _c('code', [_vm._v("<b-nav-item-dropdown>")]), _c('span', [_vm._v(" to place dropdown items within your nav.")])]), _c('div', [_c('b-nav', [_c('b-nav-item', {
    attrs: {
      "active": ""
    }
  }, [_vm._v(" Active ")]), _c('b-nav-item', [_vm._v("Link")]), _c('b-nav-item-dropdown', {
    attrs: {
      "id": "my-nav-dropdown",
      "text": "Dropdown",
      "toggle-class": "nav-link-custom",
      "right": ""
    }
  }, [_c('b-dropdown-item', [_vm._v("One")]), _c('b-dropdown-item', [_vm._v("Two")]), _c('b-dropdown-divider'), _c('b-dropdown-item', [_vm._v("Three")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }