var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Nav with Divider  "
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeDivider) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("To add divider, use ")]), _c('code', [_vm._v("<b-dropdown-divider>")]), _c('span', [_vm._v(" components.")])]), _c('div', [_c('b-nav', {
    staticClass: "wrap-border",
    attrs: {
      "vertical": ""
    }
  }, [_c('b-nav-item', {
    attrs: {
      "active": ""
    }
  }, [_vm._v(" Active ")]), _c('b-nav-item', [_vm._v("Link")]), _c('b-nav-item', [_vm._v("Another Link")]), _c('b-dropdown-divider'), _c('b-nav-item', {
    attrs: {
      "disabled": ""
    }
  }, [_vm._v(" Disabled ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }